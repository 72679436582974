.resources-gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: 100px;
    margin: 0 auto;
    width: 75%;
    gap: 1em;
    
}

.item {
    border: 1px solid black;
    
}

.grid-col-span2 {
    grid-column: span 2; 
}

.grid-row-span2 {
    grid-row: span 2;
}





