.invest-flow {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.header {
    display: flex;
    justify-content: center;
    gap: 30px;
}

.header h2 {
    cursor: pointer;
}

.header h2.active {
    text-decoration: underline;
}

.header h2.completed {
    color: gray;
}

.header h2 span {
    color: gray;
}

.step-content {
    margin-top: 20px;
    border: 1px solid black;
    padding: 20px;
}



.next-button {
    width: 100%;
    height: 50px;
    background-color: #0066ff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 18px;
}

.next-button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}