
.invest{
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 320px;
    max-width: 700px;
}

.invest > ul {
    width: 75ch;
}

.invest .li-label {
    list-style: none;
}
.invest > ul > li {
    margin-bottom: 10px;
}

.invest a {
    text-decoration: underline;
    cursor: pointer;
}

.invest form{
    display: flex;
    flex-direction: column;
}

.invest hr{
    width:100%;
    margin-top: 3em;
}


/* 2 col for the first one
.invest form {
    height: 28ex;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0;
  
}

.invest form > input, label{
    max-width: 50%;
    margin: 0;
    padding: 0;
    margin-right: 1em;
    margin-left: 1em;
}

.invest form > label {
    height: 25px;
}

.invest form > input {
    height: 30px;
}

*/