.payment-options {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}

.option-box {
    position: relative;
    width: 300px;
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.option-box h3 {
    color: #333;
    margin-bottom: 10px;
}

.option-box ul {
    list-style-type: none;
    padding: 0;
}

.option-box li {
    margin-bottom: 5px;
    line-height: 1.5;
}

.option-box.ach {
    background-color: #c4c4c4;
    cursor: not-allowed;
}

.option-box.wire {
    background-color: #f0f0f0;
}

.option-box.selected {
    background-color: #b8e994; /* light green */
}

.checkmark {
    position: absolute;
    top: 10px;
    right: 10px;
    color: #333;
}
