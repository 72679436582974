.sign-documents {
    width: 80%;
    margin: 0 auto;
    font-size: 16px;
}

.sign-documents h2 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: bold;
}

.sign-documents .document-links {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.5rem;
}


.sign-documents .document-links a {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

.sign-documents table {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 20px;
    border-collapse: collapse;
}

.sign-documents table td {
    padding: 10px;
    border: 1px solid #ccc;
}

.sign-documents button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #0066ff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
