.photo-gallery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }
  
  .photo-gallery h2 {
    margin-top: 30px;
  }
  
  .photo-gallery-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .photo-gallery-buttons button {
    background-color: #0095f6;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 20px;
    height: 40px;
    margin-left: 10px;
    width: 40px;
  }
  
  .photo-gallery-row {
    display: flex;
    overflow: scroll;
    width:100%;
  }
  
  .photo-wrapper {
    flex-shrink: 0;
    width: 50%;
    /* padding-right: 20px; */
  }
  
  .photo-wrapper img {
    max-width: 100%;
    height: 300px;
    object-fit: cover;
    padding-right: 20px;
  }
  

/* .gallery-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .gallery-title {
    font-size: 32px;
    margin-bottom: 20px;
  }
  
  .gallery {
    display: flex;
    overflow: hidden;
    width: 80%;
    transition: transform 0.5s ease;
  }
  
  .gallery img {
    width: calc(50% - 4%);
    margin-right: 20px;
    padding: 0 2%;
  }
  
  .arrow-buttons {
    display: flex;
    justify-content: flex-end;
    width: 80%;
    margin-bottom: 20px;
  }
  
  .arrow-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #333;
    color: #fff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    margin: 0 5px;
    cursor: pointer;
  }
   */