.photo-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 20px;
}

.photo-gallery h2 {
    margin-top: 30px;
    text-decoration: underline;
}

.photo-gallery-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;

}

.photo-wrapper-2 {
    flex-shrink: 0;
    width: 20%;
    margin: 0 2%; /* Adjust the percentage as needed to increase/decrease the spacing */
}

.photo-wrapper-2 img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.photo-wrapper-2:hover img {
    cursor: pointer;
    position: relative;
    transform: scale(1.20); /* Modify scale value here */
    z-index: 1;
}

/* existing styles... */

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  
  .popup-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
  }
  
  .popup-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.5);
  }
  
  .close {
    position: absolute;
    top: -60px;
    left: -50px;
    color: white;
    font-size: 15px;
    cursor: pointer;
    background-color: black;
    border-radius: 10%;
    padding-right: 5px;
    padding-left: 5px;
}
  
    /* .photo-gallery-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 20px;
  } */
  
  /* .photo-gallery-buttons button {
    background-color: #0095f6;
    border: none;
    border-radius: 50%;
    color: white;
    cursor: pointer;
    font-size: 20px;
    height: 40px;
    margin-left: 10px;
    width: 40px;
  }
   */
  /* .photo-gallery-row {
    display: flex;
    overflow: scroll;
    width:100%;
    position: relative; 
  } */