.confirmation-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: #333;
    background-color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.confirmation-page .icon {
    margin-right: 10px;
}

.message-box {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b8e994; /* light green */
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.instructions {
    text-align: center;
    margin-bottom: 20px;
}

.instructions h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
}

.instructions p, .instructions small {
    margin-top: 10px;
}

.legal-notice {
    width: 80%;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    text-align: justify;
}