.select-shares {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.select-shares .options {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: 20px auto;
}

.select-shares .option {
    border: 1px solid #ddd;
    padding: 20px;
    cursor: pointer;
    background-color: #f5f5f5;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.3s ease;
    width: calc(50% - 10px); /* This allows two options per row */
    margin-bottom: 20px;
    box-sizing: border-box;
}

.select-shares .option.active {
    background-color: #007bff;
    color: white;
}

.select-shares .amount {
    width: 80%;
    margin: 0 auto 20px;
    text-align: center;
}

.select-shares .disclaimer {
    width: 80%;
    margin: 0 auto 20px;
    text-align: center;
}

.select-shares .amount input {
    text-align: center;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
}

.select-shares .amount .input-container {
    position: relative;
    width: 100%;
}


.select-shares .amount input {
    text-align: center;
    width: 100%;
    padding: 10px;
    padding-left: 20px; /* Add space for the dollar sign */
    margin-bottom: 10px;
    box-sizing: border-box;
}

.select-shares label{
    cursor: pointer;
}