@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

body {
  font-family: 'Poppins', sans-serif;
  background-color: white;
  margin: 0;
  height: 100%;
}

.catalina-village {
  display: flex;
  height: 100vh;
}

.catalina-village-highlights {
  display: flex;
}

.left-section {
  width: 60%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  justify-content: center;
}

.left-section-highlights {
  width: 60%;
  padding: 2rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  justify-content: center;
}

.left-section-highlights p {
  width: 70%;
}

.left-section-highlights img {
  width: 400px;
  height: auto;
}

.left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add this line */
  height: 100%; /* Add this line */
}

.left-content-boxes{
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  align-items: center;
  justify-content: center;
  height: 100%;
  /* position: absolute;
  margin-top: 28%; */
}

.left-content-bldgback{
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  height: 100%;
  background-image: url(photos/oldTownCondo.svg);
  width: 50%;
  /* margin-left: auto;
  margin-right: 0; */
}


.right-section {
  width: 40%;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.right-section-2 {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.right-section-2 img {
  width: 400px;
  height: auto;
  padding: 2rem;
}

.right-section-text {
    width: 60%;
    padding: 2rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

.right-section-text p {
  width: 70%;
  text-align: center;
}

.right-section-2 h2 {
  text-align: center;
}

.title {
  font-size: 55px;
  margin: 0;
  color: #1a1a1a;
  text-align: center;
}

.location {
  font-size: 34px;
  margin-top: 8px;
  color: #1a1a1a;
  text-align: center;
}

.info-boxes {
  display: grid;
  grid-template-columns: repeat(3, 180px);
  grid-gap: 0.5rem;
  column-gap: 1ch;
  margin-top: 5rem;
  width: 100%;
  justify-content: center;
}

.info-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0f0ff; /* Even lighter blue */
  padding: 0rem; /* Adjust the padding */
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 180px;
  height: 100px;
  box-sizing: border-box;
  margin: 0 0 1px 0; /* Add this line */
  padding-top: 10px;
  padding-bottom: 10px;
}



.info-item h3 {
  font-size: 20px;
  color: #1a1a1a;
  margin-bottom: 0rem;
}

.info-item p,
.info-item input {
  font-size: 18px;
  color: #1a1a1a;
}

.info-item input {
  width: 50%;
  border: none;
  background-color: white;
  border-radius: 4px;
  text-align: center;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: large;
}

.info-text-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.info-text-button p {
  margin-right: 4px; /* Adjust the space between the text and the icon */
}

.info-icon {
  width: 12px; /* Adjust icon size */
  height: 12px; /* Adjust icon size */
  cursor: pointer;
  margin-bottom: 5px;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

button:focus {
  outline: none;
}



.invest-item:hover {
  transform: scale(1.05);
  cursor: pointer;
}

.invest-item h3 {
  font-size: 24px;
  color: white;
  line-height: 0px;
  margin: none;
} 

.invest-item p {
  font-size: 30px;
  color: white;
  line-height: 0px;
}



.right-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progress-section {
  display: flex;
  width: 100%;
}

.left-progress {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
}

.right-progress {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4rem;
}

.progress-item {
  width: 100%;
  margin-bottom: 1rem;
}

.progress-bar {
  width: 100%;
  height: 40px;
  background-color: lightblue;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  
}

.progress-bar-fill {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: darkblue;
  color: white;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;
}


.highlights-section {
  text-align: center;
  justify-content: center;
  margin: 100px;
}

.management {
  background-color: #ECF9FF;
  text-align: center;
  justify-content: center;
  margin: 0px;
  margin-top: 50px;
  padding-bottom: 50px;
}

.management h1 {
  padding-top: 30px;
}

.management p {
  padding-left: 10rem;
  padding-right: 10rem;
  padding-top: 20px;
  font-size: 14px;
}

.landuse {
  background-color: #1AAEF2;
  text-align: center;
  justify-content: center;
  margin: 0px;
  margin-top: 0px;
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
  }

  .container-landuse {
    padding: 20px; /* adjust as needed */
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .header-1-landuse {
    font-size: 2.em; /* adjust as needed */
    text-decoration: underline;
  }
  
  .header-2-landuse {
    font-size: 1.5em; /* adjust as needed */
    text-decoration: none;
  }
  
  .paragraph-landuse {
    font-size: 1em; /* adjust as needed */

  }

  .info-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: slide-up 0
  }

  .info-popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 60vw; /* control the width of the content */
    max-height: 80vh; /* control the height of the content */
    overflow-y: auto; /* add scroll if content is too large */
    box-sizing: border-box;
    text-align: center;
    animation: slide-up 0.5s ease;
  }

  @keyframes slide-up {
    0% {
      transform: translateY(100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }