.privacy-policy {
    width: 100%;
    text-align: left;
    padding: 3rem;
    font-family: Arial, sans-serif;
  }
  
  .h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  .h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .h3 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .ol {
    font-size: 1rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
  }

  .ul {
    font-size: 1rem;
    margin-bottom: 2rem;
    padding-left: 2rem;
  }
  
  .li {
    margin-bottom: 0.5rem;
  }
  
  .a {
    text-decoration: none;
    color: blue;
  }
  
  table {
    border-collapse: collapse;
    width: 100%;
  }
  
  table th, table td {
    border: 1px solid #ddd;
    padding: 15px;
    text-align: left;
  }
  
  table th {
    background-color: #f2f2f2;
  }