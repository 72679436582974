.signature-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.signature-input {
    margin-top: 10px;
    padding: 5px;
}

.signature-input.valid {
    border: 2px solid green;
}

.signature-input.invalid {
    border: 2px solid red;
}
