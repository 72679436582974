
*,
*::before,
*::after {
  box-sizing: border-box;
  
}

/* .root, html, body, p{
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6,p{
  padding: 0;
  margin: 0;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
} */

img,
picture,
svg {
  max-width: 100%;
}

.container4home h1{
  text-align: center;
  font-size: 25px;
}

.container4home h2{
  text-align:center;
  font-size: 20px;
  width: 100%;
}

.container4home p{
  font-size:15px
  
}
.container4home h1, .container4home h3, .container4home p {
  font-family: futura, sans-serif;
  padding:10px
}
.home li{
  padding:10px;
}
.container4home img{
  object-fit: cover;
}

.container4home .btn {
  display: inline-block;
  /* color: black;  */
  border: hidden;
  padding: 10px;
  margin: 20px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.container4home .btn-disabled{
  cursor: default;
}

.homebigtxt {
  font-size: 40px;
  align-content: center;
  vertical-align: middle; 
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bolder;
  float: left;
  width: 100%;
}

.picture {
  float: left;
  margin: 10px;
  padding: 10px;
  padding-left: 10%;
}

.container4home {
  display: table;
  height: auto;
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  text-align: center;
}

span {
  display: table-cell;
  vertical-align: middle;
  max-width: 500px;
}

.container4homeblk img, .container4home img {
  max-width: 700px;
  max-height: 400px;

}

.container4homeblk {
  display: table;
  height: 300px;
  width: 100%;
  padding: 20px;
  background: black;
  text-align: center;
}


.container4home ul {
  list-style-type: none; 
  padding: 0; 
  margin: 0;
  overflow: hidden;
}

.container4home li {
float: left;
max-width: 33%;
}

/*CSS for btn hover
   .btn:hover {
  background-color: #D10606;
} */

div .scrollmenu {
  background-color: white;
  overflow: auto;
  white-space: nowrap;
}

div .scrollmenu a {
  display: inline-block;
  color: black;
  text-align: center;
  padding: 14px;
  text-decoration: none;
  max-width: 40%;
}

/* gets rid of scroll bar for Chrome, Safari and Opera */
.scrollmenu::-webkit-scrollbar {
  display: none;
}


/* profile */

.profile-container{
  display: flex;
}

.left-sidebar{
  background:white;
  width: 200px;
  height:400px;
  margin:10px;
  display:grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  flex-shrink: 0;
  box-shadow: -1px -1px 20px #ECEDEE;
  cursor: pointer;
}

.sidebar-item{
  width:100%;
  color: black;
  display:flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
}

.right-content{
  margin: 10px;
  padding: 10px;
  color:black;
  width: 100%;
  
  
}

/* Portfolio */

.portfolio-box{
  background-color: white;
  color: black;
  display: grid;
  grid-template-columns: 1fr 4fr;
    border: 1px solid #ECEDEE;
  box-shadow: -1px -1px 5px #ECEDEE;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.portfolio-box .item{
  background-color: white;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.portfolio-box .item h1{
  line-height: 0px;
}
.portfolio-box .item p{
  line-height: 0px;
}



.portfolio-box .item-right{
  background-color: white;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
  gap: 10px;
}


.portfolio-box .item-right .widget{
  display: flex;
  flex-direction: column;
  height: 200px;
  width: 200px;
  flex-grow: 1;
  text-align: center;
  border: 1px solid #ECEDEE;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 5px #ECEDEE;
  
  
}

.box-shadow{
  border: 1px solid #ECEDEE;
  box-shadow: -1px -1px 5px #ECEDEE;
}

.portfolio button{
  background-color:white;
  color: black;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.portfolio .horizontal-container{
  border: 1px solid #ECEDEE;
}

/* Portfolio Quick View Slider */

.portfolio .horizontal-container .slider{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  height:30px;
}

.portfolio .horizontal-container .slider img{
  height: inherit;
  background-color: white;
}

.portfolio .horizontal-container .slider .circles{
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.portfolio .horizontal-container .slider .circle{
  height: 10px;
  width: 10px;
  border: 1px solid white;
  border-radius: 100%;
}

.portfolio .horizontal-container .slider .circle.selected{
  height: 10px;
  width: 10px;
  border: 1px solid white;
  border-radius: 100%;
  background-color: white;
}


.portfolio .horizontal-container .slider img:hover{
  height: inherit;
  background-color: white;
  cursor: pointer;
  background-color: gray;
}


.portfolio .horizontal-container .slider .right-arrow{
  float: right;
}

/* Portfolio Table View */

.portfolio table {
	width:100%;
	border:1px solid #EEEEEE;
  justify-content:center;
	align-items:center;
  text-align: center; 
  color: black;
  
}

.portfolio .portfolio-box img {
  max-height: 400px;
  max-width: 600px;
  height: 400px;
  
}

.btn {
  display: inline-block;
  /* color: black;  */
  border: hidden;
  padding: 10px;
  margin: 20px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.container4home2col {
  display: flex;
  flex-wrap: wrap;
  height: 300px;
  width: 100%;
  padding: 20px;
  background: #FFFFFF;
  text-align: center;
}

.colcat {
  flex: 0 0 50%;
  /* padding: 10px; */
  display: table;
}

.tableCell{
  display: table-cell;
  vertical-align: middle;
  max-width: 100%;
}
.test{
  display: flex;
  flex-direction: row;
  text-align: center;
  width: 100%;
  height: 300px;
}
.hack{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}