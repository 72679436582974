/* FinishProfile.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.container form p{
  margin-left: 70px;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 70px;
}

h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}

.container p{
  text-align: center;
  margin-bottom: 10px;
}

.formField {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
}

.inputContainer {
  display: flex;
  align-items: center;
  width: 70%;
}

.formField input,
select {
  width: 100%;
  padding: 8px 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.formField label {
  width: 30%;
  margin-right: 10px;
  font-weight: bold;
  text-align: right;
}

.errorMsg {
  color: red;
  font-size: 0.8rem;
  margin-left: 5px;
  min-height: 1em;  /* Add this line */
}

.countryErrorMsg {
  color: red;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 15px;
}

.saveBtn {
  font-size: 1rem;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 300px;
  margin-left: 20px;
}

.saveBtn {
  background-color: #ccc;
  color: #fff;
  margin-left: 70px;
}

.saveBtn.enabled {
  background-color: #007bff;
}

.skipBtn {
  background-color: #ffa500;
}

.inputError {
  border: 1px solid red;
}

.checkboxContainer {
  display: flex;
  margin-left: 70px;
  align-items: center;
  margin-bottom: 20px;
}

.checkboxContainer label {
  display: flex;
  align-items: space-around;
  font-size: 14px;
  width: 100%;
}

.checkboxContainer input {
  margin-right: 10px;
}


