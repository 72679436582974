.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  .title {
    color: #4A4A4A;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .text {
    color: #8A8A8A;
    font-size: 1.5rem;
    margin-bottom: 2rem;
    text-align: center;
  }
  
  .link {
    color: #ffffff;
    background-color: #007BFF;
    padding: 1rem 2rem;
    text-decoration: none;
    font-size: 1.25rem;
    border-radius: 0.5rem;
    transition: background-color 0.3s ease;
  }
  
  .link:hover {
    background-color: #0056b3;
  }
  