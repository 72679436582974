.about-section-about {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    padding: 2rem;
}

.about-topic-about {
    width: 60%;
    text-align: center;
}

.about-header-about {
    font-size: 2rem;
    color: #333;
    margin-bottom: 1rem;
}

.about-icon-about {
    margin-right: 0.5rem;
}

.about-text-about {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* Add animation */
@keyframes slide-in-from-left-about {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-from-right-about {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-from-left-about {
    animation: 1s ease-out 0s 1 slide-in-from-left-about;
}

.slide-in-from-right-about {
    animation: 1s ease-out 0s 1 slide-in-from-right-about;
}
